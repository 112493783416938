:root {
  --bottom-border-color: #00aaff;
  --white: #ffffff;
  --grayBackground: rgba(53, 78, 95, 0.6);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", "Roboto", -apple-system;
}
.background {
  display: flex;
  width: 100%;
  height: 85vh;
  position: relative;
}

.background-img {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    url("./assets/img/TorontoBackground.png");
  display: flex;
  align-items: flex-end;
  width: 100%;
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.background-img-footer {
  display: flex;
  width: 100%;
  background-color: var(--grayBackground);
  height: 150px;
  color: white;
  align-items: flex-end;
  position: relative;
}

.cityscape-outline {
  position: absolute;
  height: 14vw;
  max-height: 120px;
  right: 0px;
  bottom: 150px;
}

.agent-display-pic {
  position: absolute;
  max-width: 50vw;
  min-width: 240px;
  max-height: 57vh;
}

.footer-conatiner-2 {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
  right: 2%;
  left: 10;
  font-family: "Open Sans", "Roboto";
  font-style: italic;
  font-size: 18px;
  margin: 5px 0;
}

.footer-logo {
  position: relative;
  right: 10px;
  max-height: 60%;
  align-self: flex-end;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  height: fit-content;
  background: var(--white);
}

.content-container {
  position: relative;
  display: flex;
  justify-content: center;
  top: 10px;
  width: 95%;
  height: fit-content;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 40px #c0c0c0;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.content-title {
  margin-top: 30px;
  color: #3c4858;
  font-size: 35px;
  /* border-bottom: 1px solid var(--bottom-border-color); */
  font-weight: 500;
  height: fit-content;
}

.content-subtitle {
  margin: 10px 100px;
  color: #3c4858;
  font-size: medium;
  font-weight: 400;
}

.card-heading {
  margin: 10px 0px 30px 0;
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .background-img {
    background-position: left;
  }
  .footer-conatiner-2 {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
  .header {
    position: relative;
    top: 16px;
    font-size: 2.6vw;
  }
  .footer-logo {
    max-height: 40%;
  }

  .content-subtitle {
    margin: 10px 30px;
  }

  .agent-display-pic {
    position: absolute;
    max-width: 35vw;
    min-width: 215px;
    max-height: 60vh;
  }
}

@media only screen and (max-width: 481px) {
  .background-img {
    background-position: left;
  }
  .footer-conatiner-2 {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }
  .header {
    position: relative;
    font-size: 2.9vw;
  }
  .footer-logo {
    max-height: 40%;
  }

  .content-subtitle {
    margin: 10px 30px;
  }

  .agent-display-pic {
    position: absolute;
    max-width: 40vw;
    min-width: 225px;
    max-height: 53vh;
  }
}
